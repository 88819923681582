import { EventsListWrapper } from "components/EventsList";
import { MaxWidth } from "components/MaxWidth";
import { LARGE } from "shared/variables";
import styles from "./EventBlock.module.css";

export const EventBlock = ({ title, events }) => (
  <div className={styles.container}>
    <MaxWidth>
      <h2 className={styles.title}>{title}</h2>
    </MaxWidth>
    <MaxWidth size={LARGE}>
      <EventsListWrapper events={events} />
    </MaxWidth>
  </div>
);
