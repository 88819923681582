export function dateTimeToNumbersString(isoTime) {
  const d = new Date(isoTime);
  const day = `0${d.getDate()}`.slice(-2);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const formattedDate = `${day}-${month}-${d.getFullYear()}`;
  return formattedDate;
}

export function dateNumbersStringToTime(string) {
  const [day, month, year] = string.split("-");
  const time = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10)
  ).getTime();

  return time;
}

export function dateTimeToStringWithMonth(isoTime, full, withYear) {
  const fullMonths = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ];

  const months = full ? fullMonths : shortMonths;

  const d = new Date(isoTime);
  const date = d.getDate();
  const month = months[d.getMonth()];
  const year = d.getFullYear();

  return `${date} ${month}${withYear ? ` ${year}` : ""}`;
}
