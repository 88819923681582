import smoothscroll from "smoothscroll-polyfill";

if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

export const smoothScrollToID = (id) => {
  const element = document.querySelector(`#${id}`);
  const topOffset = element?.getBoundingClientRect().top;
  const top = topOffset + window.scrollY;

  window.scrollTo({ top, left: 0, behavior: "smooth" });
};
