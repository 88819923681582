import cx from "classnames";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { dateTimeToStringWithMonth } from "shared/dateUtils";
import { GREEN, GREY, SMALL } from "shared/variables";
import styles from "./EventsList.module.css";

export const EventsListWrapper = ({ events, maxItems }) => {
  const mappedEvents = events
    .filter((ev) => ev.uid !== "test")
    .map(({ data, uid }) => ({
      buttonLink: data.button_link,
      days: data.days,
      location: data.location,
      soldOut: data.sold_out,
      speaker: data.speaker,
      startDate: data.start_date,
      title: data.title,
      type: data.type,
      uid,
      tickets: data.tickets,
    }));

  return <EventsList events={mappedEvents} maxItems={maxItems} />;
};

export const EventsList = ({ events, maxItems = 5 }) => {
  const showAllLink = events.length > maxItems;

  return (
    <div className={styles.list}>
      {events
        .sort((a, b) => (b.startDate < a.startDate ? 1 : -1))
        .map((data) => (
          <EventRow key={data.title} {...data} />
        ))}
      {showAllLink && (
        <Link className={styles.showAll} type="agenda_overview">
          Bekijk volledige agenda
        </Link>
      )}
    </div>
  );
};

const EventRow = ({
  buttonLink,
  days,
  location,
  soldOut,
  speaker,
  startDate,
  title,
  type,
  uid,
  tickets,
}) => {
  const link = Boolean(tickets)
    ? { type: "event", uid }
    : buttonLink.link_type === "Any"
    ? { type: "agenda_detail", uid }
    : buttonLink;
  const dateString = dateTimeToStringWithMonth(startDate, false);
  const [date, month] = dateString.split(" ");

  return (
    <div className={styles.row}>
      <div className={styles.dateContainer}>
        <div className={styles.mobileDate}>
          <div className={styles.date}>{date}</div>
          <div className={styles.month}>{month}</div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div>
          <Link className={styles.titleContainer} {...link}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.labelsContainer}>
              <div className={styles.label}>{type}</div>
              {days && (
                <div className={styles.label}>
                  {days} {days > 1 ? "dagen" : "dag"}
                </div>
              )}
              {soldOut === "Ja" && (
                <div className={cx(styles.label, styles.yellow)}>
                  Uitverkocht
                </div>
              )}
            </div>
          </Link>
          <div className={styles.information}>
            {speaker && (
              <>
                Spreker: {speaker}
                <br />
              </>
            )}
            {location}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            color={soldOut === "Ja" ? GREY : GREEN}
            size={SMALL}
            link={link}
          >
            {soldOut === "Ja" ? "Meer info" : "Meer info en aanmelden"}
          </Button>
        </div>
      </div>
    </div>
  );
};
